import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { SharedServiceService } from "../shared/shared-service.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-feed-back",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./feed-back.component.html",
  styleUrl: "./feed-back.component.scss",
})
export class FeedBackComponent implements OnInit {
  feedbackForm: FormGroup;
  selectedRating: number | null = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private sharedService: SharedServiceService,
    private toastrService: ToastrService
  ) {
    this.feedbackForm = this.fb.group({
      guestName: ["", Validators.required],
      guestMobileNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
          ),
        ],
      ],
      guestFeedback: ["", Validators.required],
      guestStarRating: [null, Validators.required],
      entryfrom: "SukhoThai Web",
    });
  }
  ngOnInit(): void {}

  setRating(rating: number) {
    this.selectedRating = rating;
    this.feedbackForm.get("guestStarRating")?.setValue(rating);
  }
  getEmojiClass(rate: number): string {
    switch (rate) {
      case 1:
        return "angry";
      case 2:
        return "sad";
      case 3:
        return "ok";
      case 4:
        return "good";
      case 5:
        return "happy";
      default:
        return "";
    }
  }

  onSubmit() {
    this.feedbackForm.value;
    if (this.feedbackForm.valid) {
      // const formData = this.feedbackForm.value;
      this.sharedService.feedBackForm(this.feedbackForm.value).subscribe({
        next: (response) => {
          // console.log("Feedback submitted successfully:", response);
          // You can display a success message here
          this.toastrService.success("Thank you for your feedback!", "Success");
          this.selectedRating = null;
          this.feedbackForm.reset(); // Reset the form after successful submission
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 3000);
        },
        error: (error) => {
          console.error("Error submitting feedback:", error);
          // Display an error message here
          this.toastrService.error(
            "Failed to submit feedback. Please try again later.",
            "Error"
          );
        },
      });
    } else {
      console.error("Form is not valid");
    }
  }
}
