import { CommonModule, DOCUMENT, isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedServiceService } from '../shared/shared-service.service';
import * as ol from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SukothaiAnimations } from '../animation';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { LazyLoadDirective } from "../shared/lazy-load.directive";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadDirective
  ],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
  animations: SukothaiAnimations,
})
export class HomeComponent implements OnInit {
  therapy: any;
  benefits: any;
  blogs: any;
  offers: any;
  testimonial: any;
  contact: any;
  showFullText: number | null = null;
  map: ol.Map | undefined;
  defaultLat = 19.086;
  defaultLng = 72.8848;
  filteredContacts: any[] = [];
  slicedBenfits:any;
  searchQuery: string = '';  // Store the search term
  coordinates:any
  urlSafe: SafeResourceUrl ='';
  sanitizedUrl: SafeResourceUrl | undefined;
  urlSafemob: SafeResourceUrl ='';
  dsku = 'nPhhjlFge84';
  mobu = '598RXPuPO4U';

  desktop_youtube=`https://www.youtube.com/embed/${this.dsku}?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.dsku}`
  mobile_youtube=`https://www.youtube.com/embed/${this.mobu}?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.mobu}`
  customOptions: OwlOptions = {
    loop: false,
    touchDrag: true, // Enable swipe gestures
    mouseDrag: true,
    pullDrag: false,
    dots: false,
    stagePadding: 5,
    margin: 10,
    nav: true,
    autoplay: false,
    autoplayTimeout: 30000,
    autoplayHoverPause: true,

    navSpeed: 500,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
  };

  customOptions1: OwlOptions = {
    loop: true,
    touchDrag: true, // Enable swipe gestures
    mouseDrag: true,
    pullDrag: false,
    dots: false,
    stagePadding: 5,
    margin: 20,
    navSpeed: 500,
    autoplay: true,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1.5,
      },
      400: {
        items: 2.5,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3.5,
      },
    },
    nav: true,
  };

  custommedia: OwlOptions = {
    loop: true,
    touchDrag: true, // Enable swipe gestures
    mouseDrag: true,
    pullDrag: false,
    dots: false,
    stagePadding: 5,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 30000,
    autoplayHoverPause: true,

    navSpeed: 1500,
    navText: ["", ""],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3.5,
      },
      940: {
        items: 5,
      },
    },

  }
  // @ViewChild('owlElement', { static: false }) owlElement: OwlCarousel;

  constructor(private sharedService: SharedServiceService,@Inject(PLATFORM_ID) private platformId: Object, 
   @Inject(DOCUMENT) private dom:any,private sanitizer: DomSanitizer,
  private meta: Meta,
  private titles: Title,
  private cdr: ChangeDetectorRef
) { }
  ngOnInit() {
    this.getTherpyData();
    this.getHealthBenefits();
    this.getBlogs();
    this.getOffers();
    this.getTestimonial();
    // this.getContact()
    this.getContactAndCoordinates();
    if (isPlatformBrowser(this.platformId)) {
      // Only run this on the client-side (in the browser)
      this.initializeMap();
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.desktop_youtube
    );
    this.urlSafemob = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.mobile_youtube
    );
    // this.getCoordinatesFromAPI();
    // this.getContactAndCoordinates();
    this.updateSeo();
  }
  // onTranslated(event: any): void {
  //   const startPosition = event.startPosition; // Current slide position
  //   const slides = event.slides; // Slides array
  //   const totalItems = slides.length; // Total number of slides
  
  //   const prevButton = this.dom.querySelector('.owl-prev') as HTMLElement;
  //   const nextButton = this.dom.querySelector('.owl-next') as HTMLElement;
  //   // const imageElement = this.dom.querySelector('.last-slide img') as HTMLElement;
  
  //   // Disable the prev button if we're on the first slide
  //   if (startPosition === 0 && prevButton) {
  //     prevButton.style.pointerEvents = 'none';
  //     prevButton.style.opacity = '0.5'; // Optional for visual feedback
  //   } else if (prevButton) {
  //     prevButton.style.pointerEvents = 'auto';
  //     prevButton.style.opacity = '1'; // Restore visual feedback
  //   }
  
  //   // Disable the next button if we're on the last visible slide
  //   if (startPosition === totalItems - 1 && nextButton) {
  //     nextButton.style.pointerEvents = 'none';
  //     nextButton.style.opacity = '0.5'; // Optional for visual feedback
  //   } else if (nextButton) {
  //     nextButton.style.pointerEvents = 'auto';
  //     nextButton.style.opacity = '1'; // Restore visual feedback
  //   }

   
  // }
  
  

  getTherpyData() {
    this.sharedService.getTherapiesPackages().subscribe((res) => {
      this.cdr.markForCheck();
      this.therapy = res.therapies;
      // this.cdr.markForCheck();
      // console.log(this.therapy, "testing Done")
    });
  }
  getHealthBenefits() {
    this.sharedService.getHealthBenefitsPackages().subscribe((res) => {
      this.cdr.markForCheck();
      this.benefits = res;
      // this.cdr.markForCheck();
      this.slicedBenfits = this.benefits.slice(1, 7);

      //  console.log(this.slicedBenfits, "testing Done")
    });
  }
  getBlogs() {
    this.sharedService.getBlogs().subscribe((res) => {
      // Take only the first 3 items
      this.cdr.markForCheck();
      this.blogs = res.slice(0, 3);
      // console.log(this.blogs, "blogs");
    });
  }
  getOffers() {
    this.sharedService.getOffers().subscribe((res) => {
      this.cdr.markForCheck();
      this.offers = res;
      // console.log(this.offers);
    });
  }
  getTestimonial() {
    this.sharedService.getTestimonial().subscribe((res) => {
      this.cdr.markForCheck();
      this.testimonial = res.Data;
      // console.log(this.testimonial)
    });
  }

  getRatingArray(rating: number): number[] {
    const fullStars = Math.floor(rating); // Number of full stars
    const halfStar = rating % 1 !== 0 ? 1 : 0; // Check if there's a half-star
    const emptyStars = 5 - fullStars - halfStar; // Remaining empty stars
    return [
      ...Array(fullStars).fill(1),
      ...Array(halfStar).fill(0.5),
      ...Array(emptyStars).fill(0),
    ];
  }

  toggleText(index: any) {
    this.showFullText = this.showFullText === index ? null : index;
  }

  getTruncatedText(text: string, wordLimit: number): string {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }
  getUrlContext(value: any): string {
    if (value != "") {
      value = value.replace(/\s/g, "-");
      return value ? value.replace(/ /g, "-") : value;
    } else {
      return "";
    }
  }

  getContactAndCoordinates(): void {
    this.sharedService.getLocations().subscribe((response) => {
      this.cdr.markForCheck();
      this.contact = response;
  
      // Extract coordinates from each option
      this.coordinates = this.contact.flatMap((location: any) =>
        location.options.map((option: any) => ({
          latitude: parseFloat(option.latitude),
          longitude: parseFloat(option.longitude),
          name: option.ST_LOCN,
          address: option.ST_ADD,
          directURL: option.ADDR_URL,
          directAddrUrl: this.transformToGoogleMapsLink(option.latitude, option.longitude,option.cid),
          // sanitizedUrl: this.sanitizer.bypassSecurityTrustResourceUrl(option.ADDR_URL), // Sanitize URL
        }))
      );
  
      // Log only latitude and longitude
      // this.coordinates.forEach((coordinate: { latitude: any; longitude: any; }) => {
      //   console.log(`Latitude: ${coordinate.latitude}, Longitude: ${coordinate.longitude}`);
      // });
    });
  }
  
  transformToGoogleMapsLink(latitude: string, longitude: string,cid: string): SafeResourceUrl {
    const directUrl = `https://www.google.com/maps?ll=${latitude},${longitude}&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=${cid}`;
    // console.log(directUrl)
    return this.sanitizer.bypassSecurityTrustResourceUrl(directUrl);
  }
  
  // Initialize the map
  initializeMap(): void {
    const defaultCoordinates = [72.8848, 19.086]; // Default to a specific location

    // Create the map view centered at the default coordinates
    const view = new View({
      center: fromLonLat(defaultCoordinates),
      zoom: 12,
    });

    // OpenStreetMap layer
    const tileLayer = new TileLayer({
      source: new OSM(),
    });

    // Initialize the map with OSM layer
    this.map = new ol.Map({
      target: "map", // The id of the div where the map will be rendered
      layers: [tileLayer],
      view: view,
      controls: [] 
    });
  }

  // Function to filter contacts based on search input (name, city, or pincode)
  searchLocations(): void {
    if (this.searchQuery === "") {
      // If search query is empty, show all contacts and clear map markers
      this.filteredContacts = [...this.coordinates];
      this.clearMapMarkers();
      this.updateMapMarkers(this.filteredContacts);
    } else {
      const searchTerm = this.searchQuery.toLowerCase();
  
      // Filter for exact matches first
      const exactMatches = this.coordinates.filter((item: any) => 
        item.name.toLowerCase() === searchTerm  
      );
  
      // Filter for partial matches (excluding exact matches already found)
      const partialMatches = this.coordinates.filter((item: any) => 
        (item.name.toLowerCase().includes(searchTerm))
      );
  
      // Combine exact matches followed by partial matches
      this.filteredContacts = [...exactMatches, ...partialMatches];
  
      // Log the filtered results for debugging
      // console.log(this.filteredContacts, "Search Results");
  
      // Clear old markers and add new ones based on filtered results
      this.clearMapMarkers();
      if (this.filteredContacts.length > 0) {
        this.updateMapMarkers(this.filteredContacts);
        const firstLocation = this.filteredContacts[0];
        this.map?.getView().setCenter(fromLonLat([firstLocation.longitude, firstLocation.latitude]));
        this.map?.getView().setZoom(14); // Zoom in on the first result
      }
    }
  }
  
  
  
  
  

  // Clear all existing map markers
  clearMapMarkers(): void {
    this.map?.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        this.map?.removeLayer(layer);
      }
    });
  }

  updateMapMarkers(coordinates: { latitude: number; longitude: number }[]): void {
    if (this.map) {
      coordinates.forEach(coord => {
        this.addMarker(coord.latitude, coord.longitude);
      });
    }
  }


  // Add a marker at the given coordinates
  addMarker(lat: number, lng: number): void {
    const coordinates = [lng, lat];

    const marker = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
    });

    // Style for the marker
    marker.setStyle(
      new Style({
        image: new Icon({
          src: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Marker icon
          scale: 0.8,
        }),
      })
    );

    // Create a vector source and layer for the marker
    const vectorSource = new VectorSource({
      features: [marker],
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    // Add the marker layer to the map
    this.map?.addLayer(vectorLayer);

    // Set the map view center to the marker's coordinates and zoom in
    if (this.map) {
      this.map.getView().setCenter(fromLonLat(coordinates));
      this.map.getView().setZoom(14); // Zoom into the marker location (adjust zoom level as needed)
    }
  }

  // Add a marker at the given coordinates, with different colors for default and other markers
  // addMarker(lat: number, lng: number, isDefault: boolean): void {
  //   const coordinates = [lng, lat];

  //   // Choose the marker icon based on whether it's the default location
  //   const iconUrl = isDefault
  //     ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'   // Red marker for the default location
  //     : 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Blue marker for other locations

  //   // Create a feature (marker) at the given coordinates
  //   const marker = new Feature({
  //     geometry: new Point(fromLonLat(coordinates))
  //   });

  //   // Style for the marker with the appropriate icon
  //   marker.setStyle(new Style({
  //     image: new Icon({
  //       src: iconUrl, // Set the marker icon based on whether it's default or not
  //       scale: 0.8
  //     })
  //   }));

  //   // Vector layer to display the marker
  //   const vectorSource = new VectorSource({
  //     features: [marker]
  //   });

  //   const vectorLayer = new VectorLayer({
  //     source: vectorSource
  //   });

  //   // Add the marker layer to the map
  //   if (this.map) {
  //     this.map.addLayer(vectorLayer);

  //     // Only center the map on the default marker
  //     if (isDefault) {
  //       this.map.getView().setCenter(fromLonLat(coordinates));
  //       this.map.getView().setZoom(14); // Zoom into the default marker
  //     }
  //   }
  // }
  // seo
  metaData = {
    isProduct: false,
    page: "home",
  };
  updateSeo() {
    // if (this.route.snapshot.url[0].path == 'Group-Tours') {
    //   this.metaData.page = this.route.snapshot.url[0].path;
    // } else if (this.route.snapshot.url[0].path == 'World-Tours') {
    //   this.metaData.page = this.route.snapshot.url[0].path;
    //   // this.metaData.type = "Group"
    // } else if (this.route.snapshot.url[0].path == 'India-Tours') {
    //   this.metaData.page = this.route.snapshot.url[0].path;
    //   // this.metaData.type = "Group"
    // }

    //  this.metaData.page=this.dom.location.pathname.replace('-Tours','')
    // this.shareService.getMetaData(this.metaData).subscribe((data) => {
    // console.log(data)

    // this.metaData.page =
    //   this.route.snapshot.url[0].path + "/" + this.route.snapshot.url[1].path;
    this.sharedService.getMetaTitle(this.metaData).subscribe((item) => {
      this.cdr.markForCheck();
      // console.log(item);

      if (item.data.length != 0) {
        this.meta.updateTag({
          property: "og:title",
          content: item.data[0].meta_title,
        });
        this.meta.updateTag({
          property: "og:description",
          content: item.data[0].meta_description,
        });
        this.meta.updateTag({
          name: "keywords",
          content: item.data[0].meta_keyword,
        });
        this.meta.updateTag({
          name: "description",
          content: item.data[0].meta_description,
        });
        this.titles.setTitle(item.data[0].meta_title);
        // console.log(data[0].meta_description)
      }
    });
    // this.setCanonicalURL(this.dom.location.href);
  }
  // setCanonicalURL(url: string) {
  //   let link: HTMLLinkElement = this.dom.querySelector('link[rel="canonical"]');
  //   if (link) {
  //     link.setAttribute("href", url);
  //   } else {
  //     link = this.dom.createElement("link");
  //     link.setAttribute("rel", "canonical");
  //     link.setAttribute("href", url);
  //     this.dom.head.appendChild(link);
  //   }
  // }
}
