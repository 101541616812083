<div class="thank-detail-section px-4">
</div>

<div class="container feedbacks section-padding">
    <div class="feedback-box">
        <h2 class="title text-center">Feedback</h2>
        <p class="text-center">
            Thank you for choosing us!<br />
            Your feedback is crucial in helping us enhance our services.
        </p>
        <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <input formControlName="guestName" type="text" class="form-control" placeholder="Name" />
                <div *ngIf="feedbackForm.get('guestName')?.touched && feedbackForm.get('guestName')?.invalid">
                    <small class="text-danger">Name is required.</small>
                </div>
            </div>
            <div class="form-group">

                <input formControlName="guestMobileNumber" type="text" class="form-control" placeholder="Mobile No" />
                <div
                    *ngIf="feedbackForm.get('guestMobileNumber')?.touched && feedbackForm.get('guestMobileNumber')?.invalid">
                    <small *ngIf="feedbackForm.get('guestMobileNumber')?.errors?.['required']" class="text-danger">
                        Mobile number is required.
                    </small>
                    <small *ngIf="feedbackForm.get('guestMobileNumber')?.errors?.['pattern']" class="text-danger">
                        Please enter a valid 10-digit mobile number.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label>Rating</label>
                <div class="rate">
                    <ul class="feedback">
                        <li *ngFor="let rate of [1, 2, 3, 4, 5]" [ngClass]="getEmojiClass(rate)"
                            [class.active]="selectedRating === rate" (click)="setRating(rate)">

                            <!-- SVGs for angry, sad, and neutral ratings (1 to 3) -->
                            <div *ngIf="rate <= 2">
                                <svg class="eye left">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="eye right">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="mouth">
                                    <use xlink:href="#mouth"></use>
                                </svg>
                            </div>

                            <div *ngIf="rate === 3">
                                <svg class="eye left">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="eye right">
                                    <use xlink:href="#eye"></use>
                                </svg>
                            </div>

                            <!-- SVGs for good rating (4) with mouth -->
                            <div *ngIf="rate === 4">
                                <svg class="eye left">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="eye right">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="mouth">
                                    <use xlink:href="#mouth"></use>
                                </svg>
                            </div>

                            <!-- SVGs for happy rating (5) without mouth -->
                            <div *ngIf="rate === 5">
                                <svg class="eye left">
                                    <use xlink:href="#eye"></use>
                                </svg>
                                <svg class="eye right">
                                    <use xlink:href="#eye"></use>
                                </svg>
                            </div>
                        </li>
                    </ul>



                    <!-- static html code -->
                    <!-- <div>
                        <ul class="feedback">
                            <li class="angry" data-rate="1" onclick="setRating(this)">
                                <div>
                                    <svg class="eye left">
                                        <use xlink:href="#eye"></use>
                                    </svg> <svg class="eye right">
                                        <use xlink:href="#eye"></use>
                                    </svg> <svg class="mouth">
                                        <use xlink:href="#mouth"></use>
                                    </svg>
                                </div>
                            </li>
                            <li class="sad" data-rate="2" onclick="setRating(this)">
                                <div>
                                    <svg class="eye left">
                                        <use xlink:href="#eye"></use>
                                    </svg>
                                    <svg class="eye right">
                                        <use xlink:href="#eye"></use>
                                    </svg>
                                    <svg class="mouth">
                                        <use xlink:href="#mouth"></use>
                                    </svg>

                                </div>
                            </li>
                            <li class="ok" data-rate="3" onclick="setRating(this)">
                                <div></div>
                            </li>

                            <li class="good active" data-rate="4" onclick="setRating(this)">
                                <div>
                                    <svg class="eye left">
                                        <use xlink:href="#eye"></use>
                                    </svg>
                                    <svg class="eye right">
                                        <use xlink:href="#eye"></use>
                                    </svg>
                                    <svg class="mouth">
                                        <use xlink:href="#mouth">

                                        </use>
                                    </svg>
                                </div>
                            </li>
                            <li class="happy" data-rate="5" onclick="setRating(this)">
                                <div>
                                    <svg class="eye left">
                                        <use xlink:href="#eye"></use>
                                    </svg> <svg class="eye right">
                                        <use xlink:href="#eye"></use>
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <!-- end static html code -->

                    <!-- Hidden SVG Definitions -->
                    <svg xmlns="http://www.w3.org/2000/svg" style="display:none">
                        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                            <path
                                d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1">
                            </path>
                        </symbol>
                        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                            <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5">
                            </path>
                        </symbol>
                    </svg>

                </div>
                <div
                    *ngIf="feedbackForm.get('guestStarRating')?.invalid && feedbackForm.get('guestStarRating')?.touched">
                    <small class="text-danger">Rating is required.</small>
                </div>
            </div>
            <div class="form-group">
                <label>Please leave your feedback below</label>
                <textarea formControlName="guestFeedback" rows="4" class="form-control"></textarea>
                <div *ngIf="feedbackForm.get('guestFeedback')?.touched && feedbackForm.get('guestFeedback')?.invalid">
                    <small class="text-danger">Feedback is required.</small>
                </div>
            </div>
            <button type="submit" class="btn btn-primary button-feedback"
                [disabled]="feedbackForm.invalid">Submit</button>
        </form>
    </div>
</div>