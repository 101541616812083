import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, HeaderComponent, GiftCardComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'sukhoWebrepo';
 
  constructor(@Inject(DOCUMENT) private dom: Document, private meta: Meta) { }
  ngOnInit(): void {
    this.updateCanonicalURL();
    
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const navbar = document.querySelector('.navbar');
    if (window.scrollY > 250) { // Adjust the scroll value as needed
      navbar?.classList.add('scrolled');
    } else {
      navbar?.classList.remove('scrolled');
    }
  }
  updateCanonicalURL(): void {
    // const canonicalURL = this.dom.location.href;
    const canonicalURL = `${environment.baseUrl}${this.dom.location.pathname}`;
    // console.log(canonicalURL)
    var link  = this.dom.querySelector('link[rel="canonical"]')as HTMLLinkElement;

    if (link) {
      // If the canonical link already exists, update it
      link.setAttribute('href', canonicalURL);
    } else {
      // If it doesn't exist, create it
      link = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalURL);
      this.dom.head.appendChild(link);
    }
    this.meta.updateTag({ property: 'og:url', href: canonicalURL });
  }
}
