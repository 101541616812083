<!-- new footer start-->
<div class="container-fluid footer">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-3 col-12 d-none d-lg-block">
                <div class="footer-menus">
                    <h4 class="footer-menus__title">SukhoThai</h4>
                    <ul class="footer-menus__list">
                        <li><a routerLink=""><h5>Home</h5></a></li>
                        <li><a routerLink="/therapies"><h5>Therapies</h5></a></li>
                        <li><a routerLink="/about-us"><h5>About Us</h5></a></li>
                        <li><a routerLink="/city"><h5>Locations</h5></a></li>
                        <li><a routerLink="corporate"><h5>Corporate</h5></a></li>
                        <!-- <li><a href="https://ex.sukhothai.in/" target="_blank"><h5>Old SukhoThai</h5></a></li> -->
                        <li><a routerLink="/buy-gift-cards/"><h5>Gift Cards</h5></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3  col-12 d-none d-lg-block">
                <div class="footer-menus">
                    <h4 class="footer-menus__title">Quick Links</h4>
                    <ul class="footer-menus__list">
                        <li><a routerLink="/appointment"><h5>Appointment</h5></a></li>
                        <li><a routerLink="Benefits"><h5>Benefits</h5></a></li>
                        <li><a routerLink="offer"><h5>Offers</h5></a></li>
                        <li><a routerLink="blogs"><h5>Blog</h5></a></li>
                        <li><a routerLink="celeb-talk"><h5>Celeb Talk</h5></a></li>
                        <li><a routerLink="youtube-downloader" target="_blank"><h5>Youtube Downloader</h5></a></li>
                        <li><a routerLink="gst"><h5>GST</h5></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3  col-12 d-none d-lg-block">
                <div class="footer-menus">
                    <h4 class="footer-menus__title">Our SukhoThai Location</h4>
                    <ul class="footer-menus__list">
                        <li><a routerLink="/city/mumbai"><h5>SukhoThai in Mumbai</h5></a></li>
                        <li><a routerLink="/city/pune"><h5>SukhoThai in Pune</h5></a></li>
                        <li><a routerLink="/city/goa"><h5>SukhoThai in Goa</h5></a></li>
                        <li><a routerLink="/city/bangalore"><h5>SukhoThai in Bangalore</h5></a></li>
                        <li><a routerLink="/city/kashmir"><h5>SukhoThai in Kashmir</h5></a></li>
                        <li><a routerLink="/city/kathmandu"><h5>SukhoThai in Kathmandu</h5></a></li>
                    </ul>
                </div>
            </div>
            <!-- responsive footer -->
            <div class="accordion accordion-footer d-lg-none" id="accordionExample">
                <div class="accordion-item">
                    <h4 class="accordion-header" id="headingOne">
                        <button class="accordion-button accordion-footer-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Quick Link
                        </button>
                    </h4>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body d-flex">
                            <div class="footer-menus">
                                <ul class="footer-menus__list">
                                    <li><a routerLink=""><h5>Home</h5></a></li>
                                    <li><a routerLink="/therapies"><h5>Therapies</h5></a></li>
                                    <li><a routerLink="/about-us"><h5>About Us</h5></a></li>
                                    <li><a routerLink="/city"><h5>Locations</h5></a></li>
                                    <li><a routerLink="/corporate"><h5>Corporate</h5></a></li>
                                    <li><a routerLink="/buy-gift-cards/"><h5>Gift Cards</h5></a></li>
                                    <li><a href="https://www.sukhothai.in/youtube-downloader" target="_blank"><h5>Youtube Downloader</h5></a></li>
                                </ul>
                            </div>
                            <div class="footer-menus">
                                <ul class="footer-menus__list">
                                    <li><a routerLink="/appointment"><h5>Appointment</h5></a></li>
                                    <li><a routerLink="Benefits"><h5>Benefits</h5></a></li>
                                    <li><a routerLink="offer"><h5>Offers</h5></a></li>
                                    <li><a routerLink=""><h5>Priority Cards</h5></a></li>
                                    <li><a routerLink="blogs"><h5>Blog</h5></a></li>
                                    <li><a routerLink="celeb-talk"><h5>Celeb Talk</h5></a></li>
                                    <li><a routerLink="gst"><h5>GST</h5></a></li>
                                    <!-- <li><a href="https://ex.sukhothai.in/" target="_blank"><span>Old SukhoThai</span></a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="headingTwo">
                        <button class="accordion-button accordion-footer-btn collapsed " type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                            aria-controls="collapseTwo">
                            Our SukhoThai Location
                        </button>
                    </h4>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <ul class="footer-menus__list">
                                <li><a routerLink="/city/mumbai"><h5>SukhoThai in Mumbai</h5></a></li>
                                <li><a routerLink="/city/pune"><h5>SukhoThai in Pune</h5></a></li>
                                <li><a routerLink="/city/goa"><h5>SukhoThai in Goa</h5></a></li>
                                <li><a routerLink="/city/bangalore"><h5>SukhoThai in Bangalore</h5></a></li>
                                <li><a routerLink="/city/kashmir"><h5>SukhoThai in Kashmir</h5></a></li>
                                <li><a routerLink="/city/kathmandu"><h5>SukhoThai in Kathmandu</h5></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end responsive footer  -->
            <div class="col-md-3  col-12">
                <div class="footer-menus">
                    <div class="footer-menus__logo-social">
                        <div class="footer__logo">
                            <a routerLink="/">
                                <img appLazyLoad="../../../assets/img/sukhologo-resize.png" width="120" height="50"
                                    alt="SukhoThai" loading="lazy">
                            </a>
                        </div>
                        <div class="footer-menus__social">
                            <!-- <p>Follow Us</p> -->
                            <h4 class="footer-menus__social-title">Social Media</h4>
                            <ul class="list-unstyled mb-0">
                                <li><a href="https://www.facebook.com/MySukhoThai" target="_blank"
                                        aria-label="SukhoThai facebook page">
                                        <i class="fa-brands fa-facebook social-icon --f"></i>
                                    </a>
                                </li>
                                <li><a href="https://www.instagram.com/mysukhothai/"
                                        aria-label="SukhoThai instagram page">
                                        <i class="fa-brands fa-instagram social-icon --i"></i>
                                    </a>
                                </li>
                                <li><a href="https://www.youtube.com/channel/UCpPdd1kD69uIISwf1IkNqFQ?reload=9"
                                        target="_blank" aria-label="SukhoThai youtube page">
                                        <i class="fa-brands fa-youtube social-icon --y"></i>
                                    </a>
                                </li>
                                <li><a href="https://x.com/mysukhothai" target="_blank"
                                        aria-label="sukhothai twitter page">
                                        <i class="fa-brands fa-x-twitter social-icon --t"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="footer-menus__rules">
                        <a class="footer-menus__privacy-policy" routerLink="/privacy-policy">Privacy Policy</a>
                        <a class="footer-menus__term-condition" routerLink="/Terms-conditions">Terms & Condition</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- whats up icon -->

<div class="wh-api whats-up-section d-lg-none">
    <div class="wh-fixed whatsapp-pulse">
        <a href="https://api.whatsapp.com/send?phone=919870989740">
            <button class="wh-ap-btn" aria-label="whatsapp button"></button>
        </a>
    </div>
</div>

<div class="wh-api whats-up-section d-none d-lg-block">
    <div class="wh-fixed whatsapp-pulse">
        <a href="https://web.whatsapp.com">
            <button class="wh-ap-btn" aria-label="whatsapp button"></button>
        </a>
    </div>
</div>

<!-- whats up icon end -->

<!-- Footer End -->

<!-- Copyright Start -->
<div class="container-fluid copyright py-4">
    <div class="container">
        <div class="row align-items-center">
            <p class="text-center text-white mb-0">Copyrights © 2010 - {{currentYear}} SukhoThai India Pvt. Ltd. - All
                Rights Reserved
            </p>
        </div>
    </div>
</div>
<!-- Copyright End -->

<!-- scroll to top code -->
<button class="scroll-to-top-button" (click)="scrollToTop()">
    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9672 6.81087C11.9013 6.87671 11.8119 6.91369 11.7188 6.91369C11.6256 6.91369 11.5363 6.87671 11.4703 6.81087L7.85158 3.19271V12.6562C7.85158 12.7494 7.81454 12.8388 7.74861 12.9048C7.68268 12.9707 7.59326 13.0077 7.50002 13.0077C7.40678 13.0077 7.31736 12.9707 7.25143 12.9048C7.1855 12.8388 7.14846 12.7494 7.14846 12.6562V3.19271L3.52971 6.81087C3.46306 6.87297 3.37492 6.90678 3.28384 6.90517C3.19276 6.90357 3.10586 6.86667 3.04145 6.80226C2.97703 6.73785 2.94014 6.65095 2.93853 6.55987C2.93692 6.46879 2.97073 6.38064 3.03283 6.314L7.25158 2.09525C7.3175 2.02941 7.40686 1.99243 7.50002 1.99243C7.59318 1.99243 7.68254 2.02941 7.74846 2.09525L11.9672 6.314C12.033 6.37992 12.07 6.46927 12.07 6.56244C12.07 6.6556 12.033 6.74495 11.9672 6.81087Z"
            fill="#111111" />
    </svg>


</button>
<!-- end scroll to top code -->

<!-- new footer end -->