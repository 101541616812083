<div class="thank-detail-section px-4 d-none">
</div>


<div class="offer-section section-padding pt-0 d-none">
    <h1 class="primary-title text-center">Youtube Downloader</h1>
    <!-- <h3 class="heading-sub-title fw-light text-center">Elevate Your Spa Experience with Our Special Offers
    </h3> -->
    <div class="mx-auto text-center">
        <img src="../../assets/img/title-image.png" width="200" alt="title-image" loading="lazy">
    </div>
</div>

<div class="container section-padding pt-lg-2 pt-0 d-none" >
    <div class="row appointment">
        <div class="booking">
            <!-- <div class="col-md-3 pd-0 d-none d-md-block p-0">
                <img src="../../../assets/img/appointment.png" width="300" height="auto" alt="enquiry" loading="lazy"
                    class="appointment__img">
            </div> -->
            <div class="col-md-12 appointment__wrapper pl-0">
                <h2 class="appointment__title overflow-visible">Convert YouTube Video to MP3</h2>                
                    <form class="appointment__form needs-validation">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-floating">
                                    <label for="Name">YouTube URL * </label>
                                    <input type="text" formControlName="F31_YOUTUBE" placeholder="Enter YouTube URL"
                                        class="form-control">
                                    <div>
                                        <span class="appointment__invalid-msg">Please enter your name in the
                                            required field.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-floating">
                                    <div class="selectcategory">
                                        <label for="ST_BRN">Bitrate (kbps)</label>
                                        <select class="form-select" formControlName="ST_BRN">
                                            <option value="" disabled selected>128 kbps</option>                                            
                                            <option value="" disabled selected>128 kbps</option>                                            
                                            <option value="" disabled selected>320 kbps</option>                                            
                                        </select>
                                        <div>
                                            <span class="appointment__invalid-msg">Please select a Branch.</span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>


                            <div class="col-12 d-flex justify-content-start fix-btn shadow-none pt-0">
                                <button type="submit" class="btn btn-primary btn-lg btn-rounded view-all-btn w-100">Convert & Download</button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</div>

<div class="youtube-download">
    <h2 class="text-white text-center fs-1">Youtube Downloader</h2>
    <iframe src="https://yt.sukhothai.in/" frameborder="0" class="responsive-iframe"></iframe>
</div>
