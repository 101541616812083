<div class="about-image-section" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
    <div class="background-img">
        <img appLazyLoad="../../assets/img/corporate-tie-up.jpg" alt="corporate-tie-up" class="desk-banner">
        <img appLazyLoad="../../assets/img/coper/Corporate-page.jpg" alt="sukhothai-mobile" class="mob-banner">
    </div>
    <div class="overlay-effect"></div>
</div>
<!-- <div class="about-image-section">
    <div class="background-img"></div>
    <div class="overlay-effect"></div>
</div> -->

<!-- <div class="therapies-image-section">
    <div class="background-img"></div>
    <div class="overlay-effect"></div>
    <div class="all-therpies">
        <div class="container">
            <h2 class="therapies-image-section__title text-center text-white">Corporate</h2>
            <div class="mx-auto mb-1 text-center">
                <img src="../../assets/img/title-image.png" width="200" alt="title-image" loading="lazy">
            </div>
            <p class="text-center text-white">Unwind in the air of peace and tranquility at our luxury spa
        </div>
    </div>
</div> -->

<div class="corporate-section para-padding " [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
    <h1 class="primary-title text-center">Corporate</h1>
    <h2 class="heading-sub-title fw-light text-center">Tailored services for corporate clients
    </h2>
    <div _ngcontent-ng-c2929640792="" class="mx-auto text-center">
        <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Corporate-Victory" loading="lazy">
    </div>
</div>


<!-------corporate-Start------>
<div class="corporate mb-5" >
    <div class="container" [@fadeInOut]="{value:'*',params:{delay:'50ms',x:'-25px'}}">
        <div class="row d-flex align-items-center">
            <div class="col-md-4">
                <div class="corporate__img-box">
                    <img appLazyLoad="../../assets/img/corporate.jpg" width="409" height="408" class="img-fluid"
                        alt="foot-message" loading="lazy">
                </div>
            </div>
            <div class="col-md-8">
                <div class="corporate__info">
                    <!-- <h1 class="primary-title">Vodafone Victory!</h1> -->
                    <h3 class="mb-2 mb-lg-3">Vodafone Victory! </h3>
                    <!-- <div class="mx-auto mb-3">
                        <img src="../../assets/img/title-image.png" width="200" alt="title-image" loading="lazy">
                    </div> -->
                    <p class="corporate__info__description">
                        Hectic schedules, tough deadliness, long hours… A successful career generally involves all this,
                        plus lots more! SukhoThai understands that a relaxed employee is a happy employee. And happy
                        employees are great performers! That’s why SukhoThai organised a relaxation awareness session
                        for Vodafone’s employees at their corporate office at Worli, Mumbai.
                    </p>

                    <p class="corporate__info__description">
                        Tips regarding living a stress-free and healthy life were shared with them. Discount vouchers
                        for amazing massages at SukhoThai were also distributed among them. Happy with the knowledge
                        gained, they returned to their workstations feeling calm, yet super energised!
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-------corporate-End------>