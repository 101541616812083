<div class="thank-detail-section px-4">
</div>

<div class="privacy-policy">
    <div class="container">
        <h2 class="primary-title text-center section-padding">Privacy Policy</h2>
        <p class="">At SukhoThai, we value your privacy and are committed to protecting the personal information you
            share with us. This privacy policy outlines how we collect, use, and safeguard your data to ensure that
            your
            personal information is always handled with the utmost care and respect.</p>

        <h3 class="privacy-policy__title pt-4 pb-1">1. Information We Collect</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Personal Details:</h5>
                <p>When you make a booking or interact with SukhoThai, we may collect
                    personal information such as your name, contact details (email address, phone number), and
                    preferences
                    related to our services.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Payment Information:</h5>
                <p>To complete your transaction, we collect payment details
                    securely.
                    Rest assured that all financial information is processed using encrypted methods to protect your
                    privacy.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Health Information:</h5>
                <p>To ensure the best service, we may ask for specific
                    health-related
                    information (such as allergies or medical conditions) before certain treatments. This information is
                    solely used to customize your SukhoThai experience and ensure your safety.</p>
            </li>
        </ul>


        <h3 class="privacy-policy__title pt-4 pb-1">2. How We Use Your Information</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Booking & Service Management:</h5>
                <p>Your personal information allows us to manage appointments, confirm bookings, and tailor our services
                    to your preferences. It ensures a seamless experience at SukhoThai.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Communication:</h5>
                <p>We may use your contact details to send you appointment reminders, important service updates,
                    and occasional promotional offers or newsletters. You have the option to opt-out of promotional
                    communications
                    at any time.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Personalization:</h5>
                <p>The health and service preferences you share help us provide a customized therapy experience
                    and recommend services that align with your well-being goals.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">3. How We Protect Your Information</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title"> Data Security:</h5>
                <p>Your personal data is stored in secure, encrypted systems to prevent unauthorized access or
                    misuse. We continually update our technology and practices to ensure the highest level of protection
                    for your
                    information.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Limited Access:</h5>
                <p>Only authorized SukhoThai personnel who need to know your information to perform their job
                    duties will have access to it. Your privacy is a priority, and we strictly limit access to your
                    data.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">4. Cookies & Website Data</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Cookies:</h5>
                <p>Our website may use cookies to enhance your online experience. These small files help us understand
                    how visitors use our website and enable us to provide personalized content and offers. You can
                    control cookie preferences through your browser settings.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Analytics:</h5>
                <p>We may use anonymous website usage data (such as pages visited or time spent on the site) to
                    improve the functionality and user experience of our online platform.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">5. Your Rights</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Access & Update Information: </h5>
                <p>You have the right to access the personal information we hold about you and can request corrections
                    or updates at any time. Just contact our team, and we will assist you.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Opt-Out:</h5>
                <p>If you prefer not to receive promotional emails or communications, you can opt-out by following the
                    instructions in any of our emails or contacting us directly.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Deletion of Data:</h5>
                <p>Should you wish to have your personal information removed from our records, we will honor
                    your request, provided it does not conflict with any legal or regulatory requirements.</p>
            </li>
        </ul>


        <h3 class="privacy-policy__title pt-4 pb-1">6. Children’s Privacy</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Minors: </h5>
                <p>SukhoThai does not knowingly collect or store personal information from individuals under the age of
                    18 without explicit parental consent. Our services are intended for adults or children under
                    parental
                    supervision.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">7. Updates to the Privacy Policy</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Continuous Improvements:</h5>
                <p>From time to time, we may update this privacy policy to reflect changes in our
                    practices or regulations. Any updates will be posted on our website, and we encourage you to review
                    the policy
                    periodically.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">8. Contact Us</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">We’re Here to Help:</h5>
                <p>If you have any questions, concerns, or requests regarding your privacy or personal data,
                    please reach out to us. You can contact us via email, phone, or in-person at any SukhoThai location.
                    We are
                    committed to addressing any concerns and ensuring your data is handled with care.</p>
            </li>
        </ul>
    </div>
</div>