import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedServiceService } from '../../shared/shared-service.service';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from '../../safe-url.pipe';

@Component({
  selector: 'app-locator',
  standalone: true,
  imports: [CommonModule, SafeUrlPipe],
  templateUrl: './locator.component.html',

  styleUrl: './locator.component.scss'
})
export class LocatorComponent implements OnInit {
  contact: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedServiceService,
    private dialogRef: MatDialogRef<LocatorComponent>,
  ) { }
  ngOnInit() {
    this.getLocations()
  }

  closeDialog(): void {
    this.dialogRef.close(); // Programmatically close the dialog
  }
  getLocations() {
    this.sharedService.getLocations().subscribe(response => {
      this.contact = response;
      // console.log(this.contact, "Full contact data");

      // Ensure this.contact is an array
      // if (Array.isArray(this.contact)) {
      //   // Filter the data where 'label' exists and is truthy
      //   const filteredContacts = this.contact.map((item) => item.label);;
      //   console.log(filteredContacts, "Filtered contact data");
      // } else {
      //   console.error("Response is not an array:", this.contact);
      // }
    });
  }

}
