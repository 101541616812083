import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from "@angular/core";
import { NavigationEnd, provideRouter, Router } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { provideHttpClient } from "@angular/common/http";
import { provideToastr, ToastrModule } from "ngx-toastr";
import { ViewportScroller } from "@angular/common";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: (router: Router, viewportScroller: ViewportScroller) => () => {
        router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        });
      },
      deps: [Router, ViewportScroller],
      multi: true,
    },
    provideClientHydration(),
    importProvidersFrom([BrowserAnimationsModule]),
    provideHttpClient(),
    provideToastr({
      closeButton: true,
      progressBar: true,
      timeOut: 5000,
      positionClass: 'toast-top-center',
    }), provideAnimationsAsync(), provideAnimationsAsync(), provideAnimationsAsync() 
  ],
  
};
