<header>

  <div class="main-container">
    <div class="navbar" [ngClass]="{'sticky': isSticky}">
      <div class="container-fluid">
        <a routerLink="/" class="navbar__navbar-brand mobile-brand d-none d-lg-block">
          <img src="../../../assets/img/sukhologo-resize.png"  width="128" height="58" alt="SukhoThai" loading="eager">
        </a>
        <ul class="menu">
          <li>
            <a routerLink="/" class="nav-link " routerLinkActive=""
              >Home</a>
          </li>
          <li>
            <a routerLink="/therapies" class="nav-link" routerLinkActive="active">Therapies</a>
          </li>
          <li class="highlight-menus">
            <a routerLink="/appointment" class="nav-link" routerLinkActive="active">Appointment</a>
          </li>
          <li>
            <a routerLink="/about-us" class="nav-link" routerLinkActive="active">About us</a>
          </li>
          <li>
            <a routerLink="/city" class="nav-link " routerLinkActive="active">Locations</a>
          </li>
          <li>
            <a routerLink="/offer" class="nav-link" routerLinkActive="active">Offers</a>
          </li>
          <li>
            <a routerLink="/corporate" class="nav-link" routerLinkActive="active">Corporate</a>
          </li>
          <!-- <li>
          <a href="card.html" class="nav-link">Card</a>
        </li> -->
          <li>
          <a routerLink="/priority-cards" class="nav-link">Priority Cards</a>
        </li>
          <li class="highlight-menus">
            <a routerLink="/buy-gift-cards"  rel="noopener noreferrer"
              routerLinkActive="active" class="nav-link">Buy Gift Cards</a>
          </li>
          <li>
            <a routerLink="/blogs" class="nav-link" routerLinkActive="active">Blogs</a>
          </li>
          <li>
            <a routerLink="/celeb-talk" class="nav-link" routerLinkActive="active">Celeb Talk</a>
          </li>
        </ul>
      </div>
    </div>


    <nav class="navbar navbar-dark d-lg-none" [ngClass]="{'sticky': isSticky}">
      <div class="container-fluid navbar-container-fluid">
        <!-- Logo -->
        <a routerLink="/" class="navbar__navbar-brand">
          <img src="../../../assets/img/sukhologo-resize.png"  width="120" height="50" alt="brand-logo" loading="lazy">
        </a>

        <!-- Toggle button for mobile view -->
        <div class="loca-menu ">
          <div class="location-image ">
            <a role="button" (click)="openDialog()">
              <img src="../../../assets/img/loation.png" alt="location" loading="lazy">
            </a>

          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <!-- Menu items -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <div class="bg-logo">
            <a routerLink="/" (click)="isMobile1 ? close() : null">
              <img src="../../../assets/img/sukhologo-resize.png"  width="120" height="50" alt="Sukho" loading="lazy">
            </a>
            <button aria-label="Close" type="button" class="btn btn-white btn-close" (click)="close()"></button>
          </div>
          <ul class="navbar-nav">
            <li>
              <a routerLink="/" (click)="isMobile1 ? close() : null" class="nav-link ">Home</a>
            </li>
            <li>
              <a routerLink="/therapies" (click)="isMobile1 ? close() : null" class="nav-link">Therapies</a>
            </li>
            <li>
              <a routerLink="/appointment" (click)="isMobile1 ? close() : null" class="nav-link">Appointment</a>
            </li>
            <li>
              <a routerLink="/about-us" (click)="isMobile1 ? close() : null" class="nav-link">About us</a>
            </li>
            <li>
              <a routerLink="city" (click)="isMobile1 ? close() : null" class="nav-link">Locations</a>
            </li>
            <li>
              <a routerLink="/offer" (click)="isMobile1 ? close() : null" class="nav-link">Offers</a>
            </li>
            <li>
              <a routerLink="/corporate" (click)="isMobile1 ? close() : null" class="nav-link">Corporate</a>
            </li>
            <!-- <li>
              <a href="card.html" class="nav-link">Card</a>
            </li> -->
            <li>
              <a routerLink="/priority-cards" (click)="isMobile1 ? close() : null" class="nav-link">Priority Cards</a>
            </li>
            <li>
              <a routerLink="/buy-gift-cards"  (click)="isMobile1 ? close() : null"
                rel="noopener noreferrer" class="nav-link">Buy Gift Cards</a>
            </li>
            <li>
              <a routerLink="/blogs" (click)="isMobile1 ? close() : null" class="nav-link">Blogs</a>
            </li>
            <li>
              <a routerLink="/celeb-talk" (click)="isMobile1 ? close() : null" class="nav-link">Celeb Talk</a>
            </li>
          </ul>
          <div class="appointment-info">
            <div class="secondary-title">Appointments</div>
            <div class="contact-info">
              <a href="tel: 9870989740"><span>
                  <span class="call-info">Call Us :</span> 9870989740</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li>
          <a href="index.html" class="nav-link active">Home</a>
        </li>
        <li>
          <a href="therapies.html" class="nav-link">Therapies</a>
        </li>
        <li>
          <a href="appointment.html" class="nav-link">Appointment</a>
        </li>
        <li>
          <a href="about.html" class="nav-link">About us</a>
        </li>
        <li>
          <a href="locations.html" class="nav-link active">locations</a>
        </li>
        <li>
          <a href="offers.html" class="nav-link">Offers</a>
        </li>
        <li>
          <a href="corporate.html" class="nav-link">Corporate</a>
        </li>
        <li>
          <a href="card.html" class="nav-link">Card</a>
        </li>
        <li>
          <a href="corporate.html" class="nav-link">Priority Cards</a>
        </li>
        <li>
          <a href="card.html" class="nav-link">Buy Gift Cards</a>
        </li>
        <li>
          <a href="corporate.html" class="nav-link">Blogs</a>
        </li>
        <li>
          <a href="card.html" class="nav-link">Celeb Talk</a>
        </li>
      </ul>

    </div> -->
  </div>

</header>