import { Component, HostListener, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LazyLoadDirective } from "../../shared/lazy-load.directive";
import { CommonModule } from "@angular/common";
import { SukothaiAnimations } from "../../animation";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [RouterModule, LazyLoadDirective, CommonModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
   animations: SukothaiAnimations
})
export class FooterComponent implements OnInit {

  year: number;
  lastYear: number;
  currentYear: number = new Date().getFullYear();
  constructor() {
    this.year = new Date().getFullYear();
    this.lastYear = this.year - 1;
  }
  ngOnInit() {}
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.toggleButtonVisibility();
  }
  private toggleButtonVisibility() {
    const button = document.querySelector(".scroll-to-top-button");

    if (button) {
      if (window.scrollY > 400) {
        button.classList.add("show");
      } else {
        button.classList.remove("show");
      }
    }
  }
}
