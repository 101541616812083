import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { LocatorComponent } from '../../popups/locator/locator.component';
import { SharedServiceService } from '../../shared/shared-service.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, MatDialogModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'] // Fixed typo to styleUrls
})
export class HeaderComponent implements OnInit {
  isMobile1: boolean = false;
  isMobile: boolean = false;
  mobileWidth: number = 768;
  width: number = 0; // Removed the window access directly
  isBrowser: boolean; // To track if it's running in the browser
  contact: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (this.isBrowser) {
      this.checkMobile();
    }
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object, // Injecting platform ID
    public dialog: MatDialog,
    private sharedService: SharedServiceService
  ) {
    // Check if the current platform is browser
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      this.width = window.innerWidth; // Now it's safe to access the window object
      this.checkMobile();
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.isMobile = this.width < this.mobileWidth; // Access the window width safely
    }
    // this.getLocations() 
  }
  //is sticky start

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 50; // Adjust the value as needed
  }
  //is sticky end

  checkMobile(): void {
    if (this.isBrowser) {
      this.isMobile1 = window.innerWidth < 993; // Only execute in the browser
    }
  }

  close() {
    const mb = this.el.nativeElement.querySelector('.show');
    const body = document.body;
    const backdrop = document.querySelector('.offcanvas-backdrop');

    if (mb && mb.classList.contains('show')) {
      mb.classList.add('hide');
      mb.classList.remove('show');
      this.renderer.removeStyle(body, 'overflow');
    }

    if (backdrop) {
      backdrop.classList.remove('show');
    }

    const activeElements = document.querySelectorAll('.active');
    activeElements.forEach((element) => {
      this.renderer.removeClass(element, 'active');
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(LocatorComponent, {
      width: '400px', // Specify dialog width
      data: { message: 'Hello from Dialog' }, // Optional data to pass to the dialog
      disableClose: true // Prevent closing on outside click
    });
  
    // Add blur class when dialog opens
    document.body.classList.add('dialog-open');
  
    // Remove blur class when dialog closes
    dialogRef.afterClosed().subscribe(() => {
      document.body.classList.remove('dialog-open');
    });
  }
  
  closeDialog(event: Event) {
    event.preventDefault();
    this.dialog.closeAll();

  }


  
}
