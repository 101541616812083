<div class="location-section">

  <button aria-label="Close" type="button" (click)="closeDialog()" class="btn btn-white btn-close"></button>

  <div class="container" >
    <div class="accordion" id="accordionFlushExample">
      <div *ngFor="let city of contact; let cityIndex = index" class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse' + cityIndex" aria-expanded="false"
            [attr.aria-controls]="'flush-collapse' + cityIndex">{{ city.label }}</button>
        </h2>

        <div [id]="'flush-collapse' + cityIndex" class="accordion-collapse collapse" 
         data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <div class="accordion" [id]="'accordion' + cityIndex">
              <div *ngFor="let location of city.options; let locIndex = index" class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + cityIndex + '-' + locIndex"
                    aria-expanded="false" [attr.aria-controls]="'collapse' + cityIndex + '-' + locIndex">
                    <div class="location-city-area">
                      <h5 class="location-city-area__name">
                        <i class="fa-solid fa-location-dot"></i> {{ location.ST_LOCN }}
                      </h5>
                      <a [href]="'tel:' + location.F_PIN" class="location-city-area__number">
                        <i class="fa-solid fa-phone"></i> {{ location.F_PIN }}
                      </a>
                    </div>
                  </button>
                </h2>

                <div [id]="'collapse' + cityIndex + '-' + locIndex" class="accordion-collapse collapse"
                  [attr.data-bs-parent]="'#accordion' + cityIndex">
                  <div class="accordion-body">
                    <div class="location-area-detail">
                      <div class="location-area-detail__map">
                        <iframe [src]="location.ADDR_URL | safeUrl" width="100%" height="200" frameborder="0"
                          allowfullscreen></iframe></div>
                      <div class="location-area-detail__address">{{ location.ST_ADD }}</div>
                      <div class="location-area-detail__number">
                        <a [href]="'tel:' + location.F_PIN" class="location-city-area__number">
                          <i class="fa-solid fa-phone"></i> {{ location.F_PIN }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>