<div class="thank-detail-section px-4">
</div>

<div class="privacy-policy">
    <div class="container">
        <h2 class="primary-title section-padding text-center">Terms & Conditions</h2>
        <p class="">Welcome to SukhoThai! We strive to provide a luxurious and peaceful experience for all our guests.
            By booking a treatment or using our services, you agree to the following terms and conditions, ensuring that
            we can continue to serve you in the best possible way.</p>

        <h3 class="privacy-policy__title pt-4 pb-1">1. Appointments</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Booking in Advance:</h5>
                <p>To guarantee your preferred time and service, we recommend booking your appointment in advance.
                    Walk-ins are always welcome and accommodated based on availability.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Confirmation:</h5>
                <p>Once your appointment is confirmed, you will receive a booking confirmation. Please ensure that all
                    details are accurate.</p>
            </li>
        </ul>


        <h3 class="privacy-policy__title pt-4 pb-1">2. Arrival Time</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Early Arrival:</h5>
                <p>We recommend arriving 10-15 minutes before your scheduled appointment.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Late Arrivals:</h5>
                <p>If you arrive late, we will make every effort to accommodate you. However, to honor our other guests’
                    appointments, your session may be shortened in consideration of the next guest.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">3. Cancellation & Rescheduling</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Flexibility in Plans:</h5>
                <p> We understand that plans may change. You should need to cancel or reschedule your appointment, we
                    request that you provide us with at least 1 hour’ notice.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">4. Payments & Gift Cards</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Payment Options:</h5>
                <p>We accept various forms of payment, including cash, credit/debit cards, UPI and our gift cards,
                    ensuring a seamless transaction for our guests.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Gift Cards:</h5>
                <p>SukhoThai gift cards are available for purchase in different denominations and can be used toward any
                    service or product. They are non-refundable and must be presented at the time of payment.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">5. Health & Safety</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Your Comfort Matters:</h5>
                <p>If you have any specific health conditions, allergies, or injuries, please inform us during booking
                    and before your treatment so we can customize the therapy to suit your needs.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Clean & Safe Environment:</h5>
                <p>SukhoThai adheres to the highest standards of cleanliness and sanitation. Each therapy room is
                    thoroughly cleaned and sanitized to provide a safe and relaxing environment.</p>
            </li>
        </ul>


        <h3 class="privacy-policy__title pt-4 pb-1">6. Etiquette</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Respect for All:</h5>
                <p>SukhoThai is committed to providing a serene experience for all guests. We kindly request that mobile
                    phones be switched to silent mode or turned off during your visit to maintain a peaceful atmosphere.
                </p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Valuables:</h5>
                <p>We recommend leaving valuable personal belongings at home. SukhoThai will not be responsible for lost
                    or stolen items.
                </p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">7. Special Offers & Promotions</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Exclusive Offers:</h5>
                <p>We frequently provide exciting offers and promotions for our guests. These are subject to change and
                    availability, and cannot be combined with other discounts unless specifically stated.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">8. Priority Cards</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Enhanced Benefits:</h5>
                <p>Our Priority Cardholders enjoy exclusive perks, including additional service value and extended
                    validity periods. The terms of these benefits will be shared at the time of purchase.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">9. Feedback</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Your Experience Matters:</h5>
                <p>We value your feedback! If at any point you have suggestions or concerns, we encourage you to share
                    them with us so that we can continuously improve our services and exceed your expectations.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">10. Personal Information & Privacy</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Your Privacy Matters:</h5>
                <p>SukhoThai is committed to protecting your personal information. Any details you provide during
                    booking or your visit will be treated with the utmost confidentiality and only used for SukhoThai
                    purposes, such as communication regarding appointments or special offers.</p>
            </li>
        </ul>

        <h3 class="privacy-policy__title pt-4 pb-1">11. Pricing & Service Adjustments</h3>
        <ul>
            <li>
                <h5 class="privacy-policy__para-title">Transparent Pricing:</h5>
                <p>SukhoThai is committed to protecting your personal information. Any details you provide during
                    booking or your visit will be treated with the utmost confidentiality and only used for SukhoThai
                    purposes, such as communication regarding appointments or special offers.</p>
            </li>
            <li>
                <h5 class="privacy-policy__para-title">Updates to Services:</h5>
                <p>Occasionally, SukhoThai may update services or prices. Any changes will be reflected on our website
                    and communicated to guests well in advance.</p>
            </li>
        </ul>
    </div>
</div>