<div [@slideIn]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
  <!-- first video section -->
  <div class="framevid">

    <!-- desktop video -->
    <div class="desktop-video d-md-block d-none">
      <div class="desktop-video__wrapper">
        <iframe title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen [src]="urlSafe">
        </iframe>
        <div class="testC desktop-video__overlay">
        </div>
      </div>
    </div>


    <!-- mobile video -->
    <div class="video-container d-md-none">
      <div style="height:100vh; position:relative; z-index:22;"></div>
      <iframe title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowfullscreen [src]="urlSafemob">
      </iframe>
    </div>

    <div class="overlay"></div>
  </div>

  <!-- end video section -->

  <!-- services section start -->
  <div class="services-section section-padding">
    <h1 class="primary-title text-center">Our Wellness Therapies</h1>
    <p class="heading-sub-title fw-light text-center">Revitalize Your Body and Mind with Our Holistic Healing Therapies
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="wellness therapies"
        loading="lazy">
    </div>
    <div class="container section-padding">
      <div class="offerdetail">
        <owl-carousel-o #owlElement [options]="customOptions">
          <!-- (translated)="onTranslated($event)" -->
          <ng-template carouselSlide *ngFor="let item of therapy;let i = index">
            <div class="card services-card">
              <a [routerLink]="['/therapies' ,getUrlContext(item.name)]">

                <div class="services-card__img-box">
                  <img [appLazyLoad]="item.image" [alt]="item.name" class="img-fluid" width="307" height="240"
                    loading="lazy">
                </div>
              </a>
              <div class="card-body">
                <a [routerLink]="['/therapies' ,getUrlContext(item.name)]">
                  <h2 class="card-title card-para  secondary-title">{{ item.name }}</h2>
                </a>

                <!-- <p class="card-text card-para mb-0" [ngClass]="{'active': showFullText}"
            [innerHTML]="showFullText ? item.title : item.title.substring(0, 30) + '...'">
         </p> -->
                <p class="card-text card-para mb-1" [ngClass]="{'active': showFullText === i}">
                  {{ showFullText === i ? item.title : getTruncatedText(item.title, 20) }}
                </p>

                <!-- <a (click)="toggleText(i)" class="btn-read-more">
                  {{ showFullText === i ? 'Show Less' : 'Read More' }}
                </a> -->


                <p class="card-text mb-1" *ngIf="item.SPAPrice != null">
                  Therapy starts at
                </p>
                <p class="card-text" *ngIf="item.SPAPrice != null">
                  <span class="highlight-value"> {{ item.SPAPrice |currency:'INR':'symbol':'4.0' }} </span> for <span
                    class="highlight-value">{{ item.duration }}</span>
                </p>

                <a [routerLink]="['/therapies' ,getUrlContext(item.name)]"
                  class="btn btn-primary view-all-btn read-more-all">Book Now</a>
              </div>

            </div>
          </ng-template>
          <!-- <ng-template carouselSlide>
            <div class="card services-card">
           
            </div>
          </ng-template> -->
          <!-- <ng-template carouselSlide>
        <div class="card services-card">
          <a href="/Therapies">
            <div class="services-card__img-box">
              <img src="../../assets/img/foo-mesage-with-head.jpg" alt="Foot Massage with Back, Head and Shoulder"
                class="img-fluid" loading="lazy">
            </div>
          </a>
          <div class="card-body">
            <a href="/Therapies">
              <h5 class="card-title secondary-title" title="Foot Massage with Back, Head and Shoulder">Foot Massage with
                Back, Head and Shoulder</h5>
            </a>
            <p class="card-text">A relaxing massage for the tired legs & feet to help ease muscle fatigue. Reflexology
              at
              work!</p>
            <p class="card-text">Therapy starts at <span class="highlight-value"> Rs.1150 for 30 mins</span></p>
            <a href="/Therapies" class="btn btn-primary view-all-btn">Book Now</a>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide>
        <div class="card services-card">
          <a href="/Therapies">
            <div class="services-card__img-box">
              <img src="../../assets/img/foot-message-scrub.jpg" alt="Foot Massage with Scrub" class="img-fluid"
                loading="lazy">
            </div>
          </a>
          <div class="card-body">
            <a href="/Therapies">
              <h5 class="card-title secondary-title">Foot Massage with Scrub</h5>
            </a>
            <p class="card-text">A relaxing massage for the tired legs & feet to help ease muscle fatigue. Reflexology
              at
              work!</p>
            <p class="card-text">Therapy starts at <span class="highlight-value"> Rs.1150 for 30 mins</span></p>
            <a href="/Therapies" class="btn btn-primary view-all-btn">Book Now</a>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="card services-card">
          <a href="/Therapies">
            <div class="services-card__img-box">
              <img src="../../assets/img/foot-message-herbal.jpg" alt="Foot Massage with Herbal Compress"
                class="img-fluid" loading="lazy">
            </div>
          </a>
          <div class="card-body">
            <a href="/Therapies">
              <h5 class="card-title secondary-title" title="Foot Massage with Herbal Compress">Foot Massage with Herbal
                Compress</h5>
            </a>
            <p class="card-text">A relaxing massage for the tired legs & feet to help ease muscle fatigue. Reflexology
              at
              work!</p>
            <p class="card-text">Therapy starts at <span class="highlight-value"> Rs.1150 for 30 mins</span></p>
            <a href="/Therapies" class="btn btn-primary view-all-btn">Book Now</a>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="card services-card">
          <a href="/Therapies">
            <div class="services-card__img-box">
              <img src="../../assets/img/hot stone massage .png" alt="Foot Massage with Hot Stones" class="img-fluid"
                loading="lazy">
            </div>
          </a>
          <div class="card-body">
            <a href="/Therapies">
              <h5 class="card-title secondary-title">Foot Massage with Hot Stones</h5>
            </a>
            <p class="card-text">A relaxing massage for the tired legs & feet to help ease muscle fatigue. Reflexology
              at work!</p>
            <p class="card-text">Therapy starts at<span class="highlight-value"> Rs.1150 for 30 mins</span></p>
            <a href="/Therapies" class="btn btn-primary view-all-btn">Book Now</a>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="card services-card">
          <a href="/Therapies">
            <div class="services-card__img-box">
              <img src="../../assets/img/body-message.jpg" alt="Body Massages" class="img-fluid" loading="lazy">
            </div>
          </a>
          <div class="card-body">
            <a href="/Therapies">
              <h5 class="card-title secondary-title">Body Massages</h5>
            </a>
            <p class="card-text">A relaxing massage for the tired legs & feet to help ease muscle fatigue. Reflexology
              at
              work!</p>
            <p class="card-text">Therapy starts at <span class="highlight-value"> Rs.1150 for 30 mins</span></p>
            <a href="/Therapies" class="btn btn-primary view-all-btn">Book Now</a>
          </div>
        </div>
      </ng-template> -->
        </owl-carousel-o>
      </div>
    </div>
  </div>

  <!-- services section end -->

  <!-- About Start -->
  <div class="about-us-section section-padding bottom-padding bottom-margin">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-lg-5 col-md-12">
          <div class="about-us-section__about-us-image">
            <div class="button-type" data-bs-toggle="modal" data-bs-target="#about-us-video">
              <img appLazyLoad="../../assets/img/about-us/sukhothai-spa.jpg" width="406" height="585"
                alt="sukhothai-spa" loading="lazy">
            </div>
            <div class="year-of-experience">
              <div class="year-of-experience__value">
                14+
              </div>
              <div class="year-of-experience__content">Years of Experience</div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="about-us-section__overlay-content">
            <h2 routerLink="/about-us" class="primary-title about-us-heading mb-1 text-white pt-4 pt-md-0">About
              SukhoThai</h2>
            <p class="heading-sub-title text-white">The world class Thai Spa spreading Smiles & Happiness.
            </p>
            <div class="mx-auto mb-4">
              <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Sukhothai"
                loading="lazy">
            </div>
            <p class="mb-4 text-white">Established in 2010, SukhoThai
              is India's premier Foot Therapy brand the deeply rooted cultures of Thailand. The seemingly small nation
              has a great culture and is
              home to exotic fruits and flowers. The word ‘Sukho’ is derived from the Sanskrit word ‘Sukh’
              which connotes happiness and ‘Thai’ reflects its innate bond with Thailand. With over 30 SukhoThai
              Spanning across Mumbai, Pune, Goa, Bangalore, Kashmir and Kathmandu. SukhoThai is the first
              international foot massages spa chain to open a series of <a routerLink="/city"
                class="about-hightlight">outlets in India</a>.
            </p>
            <div class="award-info">
              <ul class="award-info__list">
                <li class="shadow">
                  <div class="award-info__image">
                    <i class="fa-solid fa-face-smile icon-info"></i>
                  </div>
                  <div class="award-info__caption">
                    <h3 class="">5 Lakh+</h3>
                    <p class="mb-0">Happy Guest</p>
                  </div>
                </li>
                <li>
                  <div class="award-info__image">
                    <i class="fa-solid fa-award icon-info"></i>
                  </div>
                  <div class="award-info__caption">
                    <h3 class="">10+</h3>
                    <p class="mb-0">Win Awards</p>
                  </div>
                </li>
                <li>
                  <div class="award-info__image">
                    <i class="fa-solid fa-circle-user icon-info"></i>
                  </div>
                  <div class="award-info__caption">
                    <h3 class="">400+</h3>
                    <p class="mb-0">Our Therapists</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <a href="#" class="btn btn-primary btn-primary-outline-0 rounded-pill py-3 px-5">Read More</a> -->
        </div>
      </div>
    </div>

  </div>
  <!-- About End -->

  <!-- health benefits start -->
  <div class="health-section section-padding">
    <h2 class="primary-title text-center">Health benefits of SukhoThai Foot Massage</h2>
    <p class="heading-sub-title fw-light text-center">Balance, Heal, and Enhance Your Wellbeing
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="health benefits" loading="lazy">
    </div>
    <div class="container section-padding">
      <ul class="health-benefits-list">
        <li *ngFor="let item of slicedBenfits ">
          <div class="card health-benefits-card">
            <img [appLazyLoad]="item.image" [alt]="item.imageTitle" class="card-img-top rounded" width="90" height="150"
              loading="lazy">
            <div class="card-body">
              <h3 class="card-title secondary-title">{{item.name}}</h3>
              <p class="card-text">{{item.description}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="mx-auto w-100 text-center">
      <a routerLink="/Benefits" class="btn btn-primary view-all-btn">View All Benefits</a>
    </div>
  </div>
  <!-- health benefits ends -->



  <!-- blog section end -->

  <!-- location section start here -->
  <div class="location-section section-padding bottom-margin">
    <h2 class="primary-title text-center text-white">Location</h2>
    <p class="heading-sub-title fw-light text-white text-center">Explore Our Tranquil Spaces Designed for Your Comfort
    </p>
    <div class="mx-auto text-center ">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Location" loading="lazy">
    </div>
    <div class="container section-padding">
      <ul class="location-list">
        <li>
          <a routerLink="city/mumbai" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 1.png" alt="sukhothai in Mumbai" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Mumbai</h3>
            </figure>
          </a>
        </li>
        <li>
          <a routerLink="city/pune" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 2.png" alt="sukhothai in Pune" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Pune</h3>
            </figure>
          </a>
        </li>
        <li>
          <a routerLink="city/goa" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 5.png" alt="sukhothai in Goa" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Goa</h3>
            </figure>
          </a>
        </li>
        <li>
          <a routerLink="city/bangalore" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 3.png" alt="sukhothai in Bangalore" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Bangalore</h3>
            </figure>
          </a>
        </li>

        <li>
          <a routerLink="city/kashmir" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 2.png" alt="sukhothai in Kashmir" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Kashmir</h3>
            </figure>
          </a>
        </li>

        <li>
          <a routerLink="city/kathmandu" class="city">
            <figure class="figure image-container">
              <img appLazyLoad="../../assets/img/location/banglore-1 4.png" alt="sukhothai in Kathmandu" loading="lazy"
                width="135" height="139">
              <h3 class="figure-caption secondary-title text-white">Kathmandu</h3>
            </figure>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- location section end here -->



  <!-- offer start -->
  <div class="offer-section section-padding">
    <h2 class="primary-title text-center">Our Special Offer</h2>
    <p class="heading-sub-title fw-light text-center">Elevate Your Spa Experience with Our Special Offers
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Special-Offer" loading="lazy">
    </div>
    <div class="container section-padding">
      <div class="offerdetail">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let item of offers">
            <div class="card offer-card">
              <a [routerLink]="['/offer', getUrlContext(item.title)]">
                <img [appLazyLoad]="item.image " [alt]="item.imageTitle" width="265" height="264" class="card-img-top"
                  loading="lazy">
              </a>
              <div class="card-body">
                <p class="card-desc">
                  {{item.description}}
                </p>
                <div class="offer-card__call">
                  <p class="offer-card__call-us">
                    Call us on
                  </p>
                  <p>
                    <i class="fa fa-phone" aria-hidden="true"></i>

                    <a
                      href="tel:+{{item.telephoneNumber.split(',')[0].trim()}}">{{item.telephoneNumber.split(',')[0].trim()}}</a>
                  </p>
                </div>
                <!-- <a href="#" class="d-flex gap-2 align-items-center">Read More
              <i class="fa-solid fa-arrow-right-long"></i></a> -->
              </div>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
  <!-- offer end -->

  <!-- Testimonial Start -->
  <div class="testimonial-section section-padding pt-0">
    <h2 class="primary-title text-center">Testimonials</h2>
    <p class="heading-sub-title fw-light text-center">People visit us again & again & again.
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="testimonials" loading="lazy">
    </div>
    <div class="container section-padding">
      <div class="offerdetail">
        <owl-carousel-o [options]="customOptions1">
          <ng-template carouselSlide *ngFor="let item of testimonial">
            <div class="card testi-card">
              <div class="testi-card__card-body">
                <img appLazyLoad="../../assets/img/logo/logo.png" width="88" height="88" alt="Logo"
                  class="testi-card__img-box">
                <div class="card-title-review">
                  <div class="card-title secondary-title">{{item.guestName}}</div>
                  <div class="testi-card__reviews">
                    <ng-container *ngFor="let star of getRatingArray(item.rating)">

                      <!-- Full star -->
                      <img *ngIf="star === 1" appLazyLoad="../../assets/img/star.svg" alt="star">
                      <!-- Half star -->
                      <img *ngIf="star === 0.5" appLazyLoad="../../assets/img/new-rbg-halfstar.png" alt="half star">
                      <!-- Empty star (optional) -->
                      <!-- <img *ngIf="star === 0" src="../../assets/img/empty-star.svg" alt="empty star"> Optional: Add empty star image -->
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="card-text">
                {{item.message}}
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>

  </div>

  <!-- Testimonial End -->

  <!-- contact section start -->
  <div class="media-section para-padding">
    <h2 class="primary-title text-center">Media Coverage</h2>
    <p class="heading-sub-title fw-light text-center">SukhoThai’s Wellness Excellence
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Media Coverage" loading="lazy">
    </div>
    <div class="container section-padding">
      <div class="offerdetail section-padding">
        <div class="media-offerdetail">
          <owl-carousel-o [options]="custommedia">
            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/1 The-Times-of-India.jpg" alt="The-Times-of-India"
                  class="img-fluid" loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>

            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/2 The-Economic-Times.jpg" alt="he-Economic-Times"
                  class="img-fluid" loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>

            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/3 Bombay-Times.jpg" alt="Bombay-Times" class="img-fluid"
                  loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>

            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/4 Pudhari.jpg" alt="pudhari" class="img-fluid" loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>

            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/5 Tarun-Bharat.jpg" alt="tarun-bharat" class="img-fluid"
                  loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>

            <ng-template carouselSlide>
              <!-- <a href="#"> -->
              <div class="media-section__img-box">
                <img appLazyLoad="../../assets/img/media/6 Global-Bharat.jpg" alt="global-bharatI" class="img-fluid"
                  loading="lazy">
              </div>
              <!-- </a> -->
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <!-- blog section start -->
  <div class="blog-section section-padding bottom-margin">
    <h2 class="primary-title text-center">Our Recent Blogs</h2>
    <p class="heading-sub-title fw-light text-center">Unlock insights with our recent articles
    </p>
    <div class="mx-auto text-center">
      <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Recent Blogs" loading="lazy">
    </div>
    <div class="container section-padding">
      <div class="row">
        <div class="col-md-4" *ngFor="let item of blogs">
          <div class="card services-card">
            <div class="services-card__img-box">
              <a [routerLink]="['/blogs', item.title]">
                <img [appLazyLoad]="item.image" [alt]="item.imageTitle" class="card-img-top" width="409" height="273"
                  loading="lazy">
              </a>
            </div>
            <div class="card-body">
              <div class="services-card__dates">
                <div class="services-card__trend">Trending &nbsp;
                  <span>{{item.date | date: 'MMM d, y'}}</span>
                </div>
              </div>
              <a [routerLink]="['/blogs', item.title]" class="card-title secondary-title">{{ item.title }}</a>
              <!-- <a href="#" class="d-flex gap-2 align-items-center btn-read-more">Read More
                <i class="fa-solid fa-arrow-right-long"></i></a> -->
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="mx-auto w-100 text-center">
      <a href="/blogs" class="btn btn-primary view-all-btn">View All Blogs</a>
    </div>
  </div>
  <!-- blog section end -->
  <div class="services-section section-padding pt-0">
    <div class="px-2 px-lg-0">
      <h2 class="primary-title text-center">Contact Us</h2>
      <p class="heading-sub-title fw-light text-center">Have questions? We're here to support you
      </p>
      <div class="mx-auto text-center">
        <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="Contact Us" loading="lazy">
      </div>
    </div>

    <div class="location section-padding pb-2">
      <div class="location__formdetails">
        <form>
          <div class="form-group">
            <label for="searchQuery">Select a Branch</label>
            <div class="selectarrow">
              <select class="form-control" [(ngModel)]="searchQuery" name="searchQuery" (change)="searchLocations()"
                required>
                <option value="">Select a location</option>
                <!-- Use `coordinates` to list options by branch name and address -->
                <option *ngFor="let coord of coordinates" [value]="coord.name">
                  {{ coord.name |uppercase }}
                </option>
              </select>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="container">
      <div class="place">
        <div class="place__add">
          <div class="dott pb-3" *ngFor="let item of coordinates">
            <div class="direction-icon">
              <a [href]="item.directAddrUrl" target="_blank" class="pulse-button">
                <i class="fa fa-street-view" aria-hidden="true"></i></a>
            </div>
            <h3 class="contact-branchnames">
              <span class="contact-title"> SukhoThai</span> {{ item.name | titlecase}}
            </h3>
            <p class="mb-0">{{item.address}}</p>
            <!-- <p class="mb-1">1.8 km</p> -->
            <!-- <p><a href="#">Directions</a></p> -->
            <!-- <div class="w-100">
              <a class="btn btn-primary view-all-btn" 
              [href]="item.directAddrUrl" 
                 target="_blank">
                 Directions
              </a>
            </div> -->

          </div>
        </div>

        <div class="place__map">
          <div id="map" style="height: 500px; width: 100%;"></div>
        </div>
      </div>
    </div>


  </div>
  <!-- contact section end -->

  <!-- media section start -->

  <!-- media section end -->
</div>


<!-- about us video model -->
<div class="modal fade" id="about-us-video" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true" [@slideIn]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
  <div class="modal-dialog  modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body video-popup">
        <button type="button" class="btn-close video-close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
        <iframe title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
          src="https://www.youtube.com/embed/W2NMB5RdYAk?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=W2NMB5RdYAk"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;">
        </iframe>
        <!-- <video width="100%" autoplay muted loop class="desk-video">
          <source src="https://www.youtube.com/embed/W2NMB5RdYAk?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=W2NMB5RdYAk" type="video/mp4">
        </video> -->
      </div>
    </div>
  </div>
</div>
<!-- end with about-us video -->