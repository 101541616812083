<div class="about-image-section" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
    <div class="background-img">
        <picture class="desk-banner">
            <source srcset="../../../assets/img/sukhothai-best-spa.webp" type="image/webp">
            <img appLazyLoad="../../../assets/img/sukhothai-best-spa.jpeg" alt="sukhoThai-desktop">
        </picture>

        <img appLazyLoad="../../assets/img/benefit/Health-Benefits-on-reat-all-page.jpg" alt="sukhothai-mobile"
            class="mob-banner">
    </div>
    <div class="overlay-effect"></div>
</div>
<!-- <div class="about-image-section">
    <div class="background-img"></div>
    <div class="overlay-effect"></div>
</div> -->

<div class="about-us-wrapper section-padding" [@fadeInOut]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
    <div class="container">
        <div class="row d-flex">
            <div class="col-md-6 col-12 px-2">
                <div class="about-us-wrapper__info">
                    <h1 class="primary-title text-center">About SukhoThai</h1>
                    <h2 class="heading-sub-title fw-light text-center">The world class Thai Foot Spa spreading Smiles &
                        Happiness.
                    </h2>
                    <div class="mx-auto mb-3">
                        <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16"
                            alt="About SukhoThai" class="img-fluid mx-auto d-block" loading="lazy">
                    </div>
                    <p class="about-us-wrapper__info__description">Established in 2010,
                        <a class="about-hightlight" target="_blank" routerLink="/">SukhoThai</a>
                        is India’s premier Foot Therapy brand
                        inspired from the deeply rooted cultures of Thailand. The seemingly small nation has a great
                        culture and is home to exotic fruits and flowers. The word ‘Sukho’ is derived from the Sanskrit
                        word ‘Sukh’ which connotes happiness and ‘Thai’ reflects its innate bond with Thailand. With
                        over 21 SukhoThai Spanning across Mumbai ,Pune ,Goa ,Bangalore and Kathmandu. SukhoThai is the
                        first international foot massages spa chain to open a series of <a class="about-hightlight"
                            target="_blank" routerLink="/city" routerLinkActive="router-link-active">
                            outlets in India.</a>
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <iframe class="desk-video rounded" width="100%" height="100%"
                    src="https://www.youtube.com/embed/Hvrp21ZMCGM?si=PQP0CLmo1sXaCfeG" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameborder="0" allowfullscreen>
                </iframe>
                <!-- <div class="about-us-wrapper__img-box">                    
                    <img src="../../assets/img/about-us/celeberating.jpg" class="img-fluid rounded" alt="celeberating" width="626" height="271" loading="lazy">                    
                </div> -->
            </div>
        </div>
    </div>
</div>


<div class="vision-mission" [@fadeAnimation]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
    <div class="container">
        <ul class="vision-mission__list">
            <li>
                <div class="vision-mission__info shadow">
                    <h3 class="para-title">Our Vision:</h3>
                    <p>SukhoThai – The world class Thai Foot Spa spreading Smiles & Happiness.</p>
                </div>
            </li>
            <li>
                <div class="vision-mission__info shadow">
                    <h3 class="para-title">Our Mission:</h3>
                    <p>SukhoThai is Passionately Committed to Total Quality Spa Services, with continual delivery of
                        value additions. We uphold the highest ethical standards and believe in creating new benchmarks
                        in the SPA industry.</p>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="therapies-section section-padding" [@animateStagger]="{value:'*',params:{delay:'5ms',x:'-50px'}}">
    <div class="container">
        <h2 class="primary-title text-center">SukhoThai Therapies and Staff Services </h2>
        <h6 class="heading-sub-title fw-light text-center"> Where Healing Meets Professional Excellence
        </h6>
        <div class="mx-auto mb-4 ">
            <img appLazyLoad="../../assets/img/title-image.png" width="200" height="16" alt="SukhoThai Therapies"
                class="img-fluid mx-auto d-block" loading="lazy">
        </div>
        <ul class="therapies-section__list">
            <li>
                <img appLazyLoad="../../assets/img/about-us/therepies1.jpg" class="img-fluid" alt="SukhoThai Staff"
                    width="415" height="350" loading="lazy">
            </li>
            <li>
                <img appLazyLoad="../../assets/img/about-us/therepiest2.jpg" class="img-fluid" alt="SukhoThai Room"
                    width="415" height="350" loading="lazy">
            </li>
            <li>
                <img appLazyLoad="../../assets/img/about-us/therepiest3.jpg" class="img-fluid" alt="SukhoThai client"
                    width="415" height="350" loading="lazy">
            </li>
        </ul>

    </div>
</div>

<div class="special-note-section para-padding">
    <div class="container">
        <h4 class="para-title">What makes us Special</h4>
        <ul class="special-note-section__list">
            <li>Thai Trained Therapists</li>
            <li>Studio grade soundproof glass creates a Super Quite and Most Relaxing Ambiance</li>
            <li>Creams, Balms and Aroma oils specially manufactured for SukhoThai</li>
            <li>Exotic Fruits and Ginger Tea served after your therapy are specially imported</li>
            <li>Frequently visited by Women, Couples, Corporate, Expats, Businessmen, Socialites, Celebrities and all
                the Health conscious people.</li>
        </ul>
    </div>
</div>


<div class="core-values-section para-padding">
    <div class="container">
        <h4 class="para-title">SukhoThai's Core Values</h4>
        <ul class="core-values-section__list">
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Quality</h5>
                        <p class="card-text">We are committed to providing the highest quality foot massage and spa
                            services to our guests.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Authenticity</h5>
                        <p class="card-text">We strive to preserve the traditional techniques and principles of Thai
                            massage.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Relaxation</h5>
                        <p class="card-text">We believe in creating a serene and peaceful environment where our
                            guests can truly relax and unwind.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Wellness</h5>
                        <p class="card-text">We are dedicated to promoting overall health and well-being through our
                            services.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Guest Satisfaction</h5>
                        <p class="card-text">We prioritize the satisfaction of our guests and strive to exceed their
                            expectations.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Professionalism</h5>
                        <p class="card-text">We maintain a high standard of professionalism in all aspects of our
                            business.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Community</h5>
                        <p class="card-text">We are committed to giving back to the community and supporting local
                            initiatives.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Sustainability</h5>
                        <p class="card-text">We are mindful of our environmental impact and strive to operate in a
                            sustainable manner.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title secondary-title">Ethics</h5>
                        <p class="card-text">We adhere to the highest ethical standards in our business practices.</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>