import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateComponent } from './corporate/corporate.component';
import { PriorityCardsComponent } from './priority-cards/priority-cards.component';
import { PrivacyPolicyComponent } from './privacy/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './privacy/terms-conditions/terms-conditions.component';
import { FeedBackComponent } from './feed-back/feed-back.component';
import { YoutubeDownloaderComponent } from './youtube-downloader/youtube-downloader.component';


export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
 
  {path:'privacy-policy' ,component :PrivacyPolicyComponent},
  {path:'Terms-conditions',component:TermsConditionsComponent},  
  {path:'feedback',component:FeedBackComponent},
  {path:'youtube-downloader',component:YoutubeDownloaderComponent},
  // hjh, kj
  {
    
    path: '',
    loadChildren: () => import('../app/therapy/therapy.module').then(m => m.TherapyModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/gift-card/gift-card.module').then(m => m.GiftCardModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/priority-cards/priority.module').then(m => m.PriorityModule)
  },
  // { path: 'contact-us', component: ContactUsComponent },
  { path: 'corporate', component: CorporateComponent },
  {
    path: '',
    loadChildren: () => import('../app/location/location.module').then(m => m.LocationModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/offers/offer.module').then(m => m.OfferModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/appointments/appointments.module').then(m => m.AppointmentsModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/blogs/blogs.module').then(m => m.BlogsModule)
  },
  {
    path: 'online',
    loadChildren: () => import('../app/booking/booking.module').then(m => m.BookingModule)
  },

  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**',redirectTo: '', component: HomeComponent },
];