import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
declare var google: any;
if (environment.production) {
  enableProdMode();
}
declare global {
  interface Window { googleTranslateElementInit: any; }
}

window.googleTranslateElementInit = function(lang: string = 'en') {
  new google.translate.TranslateElement({
    pageLanguage: lang,
    includedLanguages: 'en,hi,mr,gu'
  }, 'google_translate_element');
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
