import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SukothaiAnimations } from '../animation';
import { LazyLoadDirective } from '../shared/lazy-load.directive';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [RouterModule, CommonModule, LazyLoadDirective],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  animations: SukothaiAnimations,
})
export class AboutUsComponent {

}
